// AI-GEN START - ChatGPT
import React, { useEffect, useRef, useState, useCallback } from 'react'; // AI-GEN Cursor
import ReactDOMServer from 'react-dom/server';

import Chatbot from 'react-chatbot-kit'
import { MessageParser } from "./chatbot/MessageParser"
import { ActionProvider } from "./chatbot/ActionProvider"
import './main.css';
import mixpanel from 'mixpanel-browser'; // AI-GEN - Cursor
import { useChat } from './context/chatContext'; // AI-GEN - Cursor
import { Sidebar } from './components/Sidebar';
import { GraphQLResult } from '@aws-amplify/api';
import { GetMessageHistoryInput, GET_MESSAGE_HISTORY_MUTATION, GetMessageHistoryOutput } from './chatbot/graphqlOperations';
import { performMutation } from './utils/performMutation';
import { cleanUpMessage, transformMessages } from './utils/messageHelper';
import config from './config/chatbotConfig';
import ReactLoading from 'react-loading';
import CustomMessage from './chatbot/customMessages/customMessage';

const SCROLLING_TIMEOUT = 3000;


function App() {
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const scrollTimeoutRef = useRef<null | ReturnType<typeof setTimeout>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    isBlocking,
    threadId,
    isNewThreadCreated,
    setBlockingStatus,
    setShouldUnsubscribe,
    setIsNewThreadCreated
  } = useChat();

  /* istanbul ignore next */
  // TODO:: Currently the functionality is broken, need to test after it fixed
  const handleScroll = () => {
    setIsUserScrolling(true);

    if (scrollTimeoutRef.current) {
      clearTimeout(scrollTimeoutRef.current);
    }

    scrollTimeoutRef.current = setTimeout(() => {
      setIsUserScrolling(false);
    }, SCROLLING_TIMEOUT);
  };

  const enableSendButton = () => {
    const sendButton = document.querySelector('.react-chatbot-kit-chat-btn-send');

    sendButton?.removeAttribute('disabled');
  }

  const disableSendButton = () => {
    const sendButton = document.querySelector('.react-chatbot-kit-chat-btn-send');

    sendButton?.setAttribute('disabled', 'true');
  }

  useEffect(() => {
    const scrollableDiv = document.querySelector('.react-chatbot-kit-chat-message-container');

    if (scrollableDiv) {
      scrollableDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollableDiv) {
        scrollableDiv.removeEventListener('scroll', handleScroll);
      }
      if (scrollTimeoutRef.current) {
        clearTimeout(scrollTimeoutRef.current);
      }
    };
  }, []);

  // AI-GEN START - Cursor
  function chatBotValidate() {
    // The bot do not allow send new message before previous message response back or failure
    if (!isBlocking) {
      disableSendButton();
      setBlockingStatus(true);
      return true;
    }

    return false;
  }
  // AI-GEN END

  const handleInputChange = useCallback(() => {
    const chatInput: HTMLInputElement = document.querySelector('.react-chatbot-kit-chat-input')!;

    if (chatInput?.value === '' || isBlocking) {
      disableSendButton();
    } else {
      enableSendButton();
    }
  }, [isBlocking]);

  useEffect(() => {
    const chatInput: HTMLInputElement = document.querySelector('.react-chatbot-kit-chat-input')!;

    // Initially disable the button, because the chat box is empty
    disableSendButton();

    chatInput?.addEventListener('input', handleInputChange);

    // Clean up the event listener on component unmount
    return () => {
      chatInput?.removeEventListener('input', handleInputChange);
    };
  }, [handleInputChange]);
  // AI-GEN END

  // AI-GEN START - ChatGPT
  useEffect(() => {
    const button = document.querySelector('.react-chatbot-kit-chat-btn-send');

    if (isBlocking) {
      button?.setAttribute('disabled', 'true');
    } else {
      button?.removeAttribute('disabled');
    }

    handleInputChange();
  }, [isBlocking, handleInputChange]);
  // AI-GEN END

  // AI-GEN START - Cursor
  if (!process.env.REACT_APP_MIXPANEL_KEY) {
    throw Error('Mixpanel key is not set')
  }
  mixpanel.init(process.env.REACT_APP_MIXPANEL_KEY, {
    persistence: 'localStorage'
  })
  mixpanel.identify(window.sessionStorage.getItem('licenseKey') || undefined) // AI-GEN - Cursor
  mixpanel.track('App loaded'); // AI-GEN - Cursor
  // AI-GEN END

  const loadMessageHistory = async (sessionId: string | null | undefined) => {
    const licenseKey = window.sessionStorage.getItem('licenseKey');

    if (!licenseKey || !sessionId) {
      return;
    }

    const userId = window.sessionStorage.getItem('MGRUserId') || '';

    const tenantId = `${licenseKey}#${userId}`;

    try {
      setIsLoading(true);
      const result = await performMutation<GetMessageHistoryInput>(
        GET_MESSAGE_HISTORY_MUTATION,
        {
          sessionId,
          tenantId
        },
        'Get Message History Session',
        licenseKey
      ) as GraphQLResult<GetMessageHistoryOutput>;

      const messages = transformMessages(result.data);

      const allMessages = { messages }
      /* istanbul ignore next */
      const htmlMessages = messages.map((msg) => (
        ReactDOMServer.renderToString(
          <CustomMessage state={allMessages} payload={msg.id} />
        )
      ));

      cleanUpMessage();

      // Function to append HTML messages to the container
      const container = document.querySelector('.react-chatbot-kit-chat-message-container');
      container?.insertAdjacentHTML('afterbegin', htmlMessages.join('\n'));
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
  }

  /* istanbul ignore next */
  useEffect(() => {
    if (!threadId) return;
    
    // do not reload in case of new thread creation
    if (isNewThreadCreated) {
      setIsNewThreadCreated(false);
      return;
    }
    setShouldUnsubscribe(true);
    loadMessageHistory(threadId);
  }, [threadId]);

  if (process.env.NODE_ENV === 'test') {
    // Attach the function to the window object in test environment
    (window as any).chatBotValidate = chatBotValidate;
    (window as any).loadMessageHistory = loadMessageHistory;
  }

  return (
    <div id="App"> {/* AI-GEN - Cursor */}
      <Sidebar isLoading={isLoading} />
      {isLoading && <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          paddingTop: 50,
          paddingBottom: 50,
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          background: 'white',
        }}
        className="loading"
      >
        <ReactLoading height={'50px'} width={'50px'} color='black' type='spin' />
        <p>Loading...</p>
      </div>}
      <div
        style={{
          width: '100%',
          display: isLoading ? 'none' : 'block',
        }}
      >
        <Chatbot
          config={config}
          messageParser={MessageParser}
          actionProvider={ActionProvider}
          headerText='Lithium FAF CoPilot Chatbot'
          validator={chatBotValidate}
          runInitialMessagesWithHistory={true}
          disableScrollToBottom={isUserScrolling || !isBlocking} // AI-GEN - ChatGPT
        />
      </div>
    </div>
  );
}

export default App;
// AI-GEN END
import { GraphQLResult } from "@aws-amplify/api";
import { graphqlOperation } from '@aws-amplify/api-graphql';
import { ListSessionsInput, ListSessionsOutput } from "../chatbot/graphqlOperations";
import { trackMixpanel } from "./trackMixpanel";
import { AppSyncClient } from "../chatbot/appsyncClient";

export const performQuery = async <T extends ListSessionsInput>(
  operation: any,
  variables: T,
  operationName: string,
  licenseKey: string
): Promise<GraphQLResult<ListSessionsOutput>> => {
  try {
    const client = AppSyncClient.generateClient();

    const result = await client.graphql<GraphQLResult<ListSessionsOutput>>(
      graphqlOperation(operation, { input: variables }, licenseKey)
    ) as GraphQLResult<ListSessionsOutput>;
    return result;
  } catch (error: any) {
    let errorMessage = '';

    console.log(error);

    if (error.errors && error.errors[0].message === 'Unauthorized') { // AI-GEN - Cursor
      errorMessage = `[${operationName}] Unauthorized: ${error}`; // AI-GEN - Cursor
    } else {
      errorMessage = `[${operationName}] Error performing GraphQL Mutation: ${error}`;
    }

    trackMixpanel(errorMessage, variables);
    console.error(errorMessage);

    throw error; // Or handle it accordingly
  }
};


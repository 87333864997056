// AI-GEN Start - ChatGPT
import { Amplify } from 'aws-amplify';
import * as API from '@aws-amplify/api';

// Initialize Amplify
// AI-GEN Start - Cursor
const { REACT_APP_REGION, REACT_APP_API_ENDPOINT } = process.env; // AI-GEN - Cursor
if (REACT_APP_API_ENDPOINT === undefined) {
  throw Error('GraphQL API Endpoint undefined')
}
// AI-GEN End

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: REACT_APP_API_ENDPOINT, // AI-Gen Cursor
      region: REACT_APP_REGION, // AI-Gen Cursor
      defaultAuthMode: 'lambda', // AI-GEN Cursor
    }
  },
});

export const AppSyncClient = API;

// AI-Gen End
export const isEmpty = (obj: {}) => {
  return Object.keys(obj).length === 0;
}

export const extractTableName = (text: string): (string | undefined) => {
  const match = text.match(/(\/[a-zA-Z]+(\/#\d+)?(\.\d+)?)[\s|)]/);
  if (match) {
    return match[1];
  }
};

export const createLock = () => {
  return {
    isLocked: false,
    async acquire() {
      while (this.isLocked) {
        await new Promise(resolve => setTimeout(resolve, 1));
      }
      this.isLocked = true;
    },
    release() {
      this.isLocked = false;
    }
  };
}

// AI-GEN Start - ChatGPT
import React from "react"; // AI-GEN Cursor
import CustomMessage from "../chatbot/customMessages/customMessage"; // AI-GEN Cursor
import Thinking from "../chatbot/customMessages/thinking"; // AI-GEN - Cursor
import ToolCalling from "../chatbot/customMessages/toolCalling"; // AI-GEN - Cursor
import IConfig from "react-chatbot-kit/build/src/interfaces/IConfig"; // AI-GEN - Cursor

const config: IConfig = {
  initialMessages: [],
  botName: "Lithium FAF Copilot",
  customMessages: {
    custom: (props: any) => <CustomMessage {...props} />, // AI-GEN Cursor
  },
  // AI-GEN START - Cursor
  widgets: [
    {
      widgetName: 'thinking',
      widgetFunc: (props: any) => <Thinking {...props} />,
      props: [],
      mapStateToProps: []
    },
    {
      widgetName: 'functionCalling',
      widgetFunc: () => <ToolCalling text="Retrieving the user configurations ..." />,
      props: [],
      mapStateToProps: []
    },
    {
      widgetName: 'fileSearching',
      widgetFunc: () => <ToolCalling text="Searching the knowledge base ..." />,
      props: [],
      mapStateToProps: []
    }
  ]
};
// AI-GEN END

export default config;

// AI-GEN End
// AI-GEN Start - ChatGPT
import React, { createContext, useContext, useState, ReactNode } from 'react';

export interface ChatState {
  threadId: string | null | undefined; // undefined is for the first time app reloaded
  isBlocking: boolean; // AI-GEN - Cursor
  newCreatedSessionName: string | undefined;
  shouldUnsubscribe: boolean;
  isNewThreadCreated: boolean;

  setThreadId: (threadId: string | null) => void;
  setBlockingStatus: (waiting: boolean) => void; // AI-GEN - Cursor
  setNewCreatedSessionName: (condition: string | undefined) => void;
  setShouldUnsubscribe: (condition: boolean) => void;
  setIsNewThreadCreated: (condition: boolean) => void;
}

const initialState: ChatState = {
  threadId: undefined,
  isBlocking: false, // AI-GEN - Cursor
  newCreatedSessionName: undefined,
  shouldUnsubscribe: false,
  isNewThreadCreated: false,

  setThreadId: () => { },
  setBlockingStatus: () => { }, // AI-GEN - Cursor
  setNewCreatedSessionName: () => { },
  setShouldUnsubscribe: () => { },
  setIsNewThreadCreated: () => { },
};

export const ChatContext = createContext<ChatState>(initialState);

// Ensure you export ChatContext if you're using it outside this file
export const useChat = () => useContext(ChatContext);

export const ChatProvider = ({ children }: { children: ReactNode }) => {
  const [threadId, setThreadId] = useState<string | null | undefined>(initialState.threadId);
  const [isBlocking, setBlockingStatus] = useState<boolean>(initialState.isBlocking); // AI-GEN - Cursor
  const [newCreatedSessionName, setNewCreatedSessionName] = useState<string | undefined>(initialState.newCreatedSessionName);
  const [shouldUnsubscribe, setShouldUnsubscribe] = useState<boolean>(initialState.shouldUnsubscribe);
  const [isNewThreadCreated, setIsNewThreadCreated] = useState<boolean>(initialState.isNewThreadCreated);

  return (
    <ChatContext.Provider
      value={{
        threadId,
        isBlocking,
        newCreatedSessionName,
        shouldUnsubscribe,
        isNewThreadCreated,
        setThreadId,
        setBlockingStatus,
        setNewCreatedSessionName,
        setShouldUnsubscribe,
        setIsNewThreadCreated,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
// AI-GEN End
// AI-GEN START - ChatGPT
import { ActionProvider } from "./ActionProvider";

export class MessageParser {
  actionProvider: ActionProvider;

  constructor(actionProvider: ActionProvider) {
    this.actionProvider = actionProvider;
  }

  async parse(message: string): Promise<void> {
    this.actionProvider.appendNewMessage("thinking"); // AI-GEN - Cursor

    const {
      threadId,
      shouldUnsubscribe
    } = this.actionProvider.chatContext;

    // Means user is changing from a session to another session
    if (threadId) {
      if (shouldUnsubscribe) {
        this.actionProvider.unsubscribeFromMessages();
        this.actionProvider.chatContext.setShouldUnsubscribe(false);
        await this.actionProvider.subscribeToMessages(threadId);
      }

      console.log(`Sending to Thread ${threadId}`);
      this.actionProvider.sendMessage(message, threadId);

      return;
    }

    if (!threadId) {
      const userId = window.sessionStorage.getItem('MGRUserId') || 'user123';
      const createdThreadId = await this.actionProvider.createThread(userId);

      if (createdThreadId === '') return;

      this.actionProvider.chatContext.setThreadId(createdThreadId);
      this.actionProvider.unsubscribeFromMessages();
      await this.actionProvider.subscribeToMessages(createdThreadId);

      this.actionProvider.sendMessage(message, createdThreadId);
    }
    // AI-GEN END
  }
}
// AI-GEN END

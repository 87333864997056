// AI-GEN Start - ChatGPT
// AI-GEN Start - ChatGPT
import { useContext } from 'react'; // AI-GEN Cursor
// Import GraphQL operations and client
// AI-GEN START - Cursor
import {
  CREATE_THREAD_MUTATION,
  CreateThreadInput,
  CreateThreadOutput,
  PUBLISH_RESULT_SUBSCRIPTION,
  MessageResponseSubscription,
  SEND_MESSAGE_MUTATION,
  SendMessageInput,
  PublishResultOutput,
  ToolOutput, // AI-GEN START - ChatGPT
  EventType,
} from './graphqlOperations';
// AI-GEN END
import { AppSyncClient } from './appsyncClient';
import { graphqlOperation, GraphqlSubscriptionResult } from '@aws-amplify/api-graphql';
import { GraphQLResult } from '@aws-amplify/api';
import { Subscription } from 'rxjs';
import mixpanel from 'mixpanel-browser'; // AI-GEN - Cursor

// Import ChatContext
import { ChatContext, ChatState } from '../context/chatContext';
import { createCustomMessage } from 'react-chatbot-kit'; // AI-GEN - Cursor
import { ApiResponse, DataTable, MGRClient } from '../utils/MGRClient';
import { filterJson } from '../utils/toolsOutputParser';
import { createLock, extractTableName, isEmpty } from '../utils/common';
import { ERROR_MESSAGE } from '../utils/constant';
import { trackMixpanel } from '../utils/trackMixpanel';
import { performMutation } from '../utils/performMutation';

interface ChatBotMessage {
  text: string;
  id: number;
  delay?: number; // AI-GEN - Cursor
}

export const InitialiseChatContext = () => {
  return useContext(ChatContext);
}

export class ActionProvider {
  createChatBotMessage: (text: string, options?: any) => ChatBotMessage;
  setState: (updateState: (prevState: any) => any) => void;
  client: ReturnType<typeof AppSyncClient.generateClient>;
  chatContext: ChatState;
  messageSubscription: Subscription | null;
  licenseKey: string; // AI-GEN - Cursor
  MGRUrl: string;
  MGRSessionId: string;
  MGRUserId: string;
  partialMessage: string;
  MGRClient: MGRClient; // AI-GEN - ChatGPT

  constructor(createChatBotMessage: (text: string, options?: any) => ChatBotMessage, setStateFunc: (updateState: (prevState: any) => any) => void) {
    this.createChatBotMessage = createChatBotMessage;
    this.setState = setStateFunc;
    this.client = AppSyncClient.generateClient();
    this.chatContext = InitialiseChatContext();
    this.messageSubscription = null;
    this.licenseKey = window.sessionStorage.getItem('licenseKey') || ''; // AI-GEN - Cursor
    this.MGRUrl = window.sessionStorage.getItem('MGRUrl') || ''; // AI-GEN - ChatGPT
    this.MGRSessionId = window.sessionStorage.getItem('MGRSessionId') || ''; // AI-GEN - ChatGPT
    this.MGRUserId = window.sessionStorage.getItem('MGRUserId') || ''; // AI-GEN - ChatGPT
    this.MGRClient = new MGRClient(this.MGRUrl, this.MGRSessionId);  // AI-GEN - ChatGPT
    this.partialMessage = '';
  }

  // AI-GEN START - ChatGPT
  validateBasicAuthentication() {
    const licenseKey = window.sessionStorage.getItem('licenseKey');

    if (!licenseKey) {
      trackMixpanel(ERROR_MESSAGE.NO_LICENSE_KEY.DEBUG_MESSAGE);
      console.error(ERROR_MESSAGE.NO_LICENSE_KEY.DEBUG_MESSAGE);
      this.amendLatestMessage(ERROR_MESSAGE.NO_LICENSE_KEY.USER_MESSAGE);
      this.chatContext.setBlockingStatus(false);
      this.licenseKey = '';
      return false;
    }

    if (this.licenseKey === '') {
      this.licenseKey = licenseKey;
    }

    const userId = window.sessionStorage.getItem('MGRUserId');
    if (this.MGRUserId === '') {
      this.MGRUserId = userId || '';
    }

    const MGRSessionId = window.sessionStorage.getItem('MGRSessionId');

    if (!MGRSessionId) {
      trackMixpanel(ERROR_MESSAGE.NO_MGR_SESSION_ID.DEBUG_MESSAGE);
      console.error(ERROR_MESSAGE.NO_MGR_SESSION_ID.DEBUG_MESSAGE);
      this.amendLatestMessage(ERROR_MESSAGE.NO_MGR_SESSION_ID.USER_MESSAGE);
      this.chatContext.setBlockingStatus(false);
      this.MGRSessionId = '';
      return false;
    }

    const MGRUrl = window.sessionStorage.getItem('MGRUrl');
    if (!MGRUrl) {
      trackMixpanel(ERROR_MESSAGE.NO_MGR_URL.DEBUG_MESSAGE);
      console.error(ERROR_MESSAGE.NO_MGR_URL.DEBUG_MESSAGE);
      this.amendLatestMessage(ERROR_MESSAGE.NO_MGR_URL.USER_MESSAGE);
      this.chatContext.setBlockingStatus(false);
      this.MGRSessionId = '';
      return false;
    }

    /* istanbul ignore next */
    if (this.MGRUrl === MGRUrl || this.MGRSessionId === MGRSessionId) {
      this.MGRClient = new MGRClient(MGRUrl, MGRSessionId);
      this.MGRSessionId = MGRSessionId;
      this.MGRUrl = MGRUrl;
    }

    return true;
  }
  // AI-GEN END

  handleError(error: { errors: { errorType: string; message?: string; }[]; }, caller: string) {
    // For long message, GQL call will be always timeout, so we need to ignore it and nothing to do.
    if (error.errors && error.errors[0].errorType === 'Lambda:ExecutionTimeoutException') {
      return false;
    }

    this.chatContext.setBlockingStatus(false);
    if (error.errors && error.errors[0].message === 'Unauthorized') { // AI-GEN - Cursor
      this.amendLatestMessage("Unauthorized. License key provided is incorrect"); // AI-GEN - Cursor
      return true;
    }

    if (caller === 'createThread' || caller === 'sendMessage') {
      this.amendLatestMessage("Failed to send message, please try again...");
    } else {
      this.amendLatestMessage("Failed to process user configuration, please try again...");
    }
    return true;
  }

  async createThread(userId: string): Promise<string> {
    if (!this.validateBasicAuthentication()) return '';

    this.unsubscribeFromMessages();

    try {
      this.chatContext.setThreadId(null);
      const threadMutationResult = await performMutation<CreateThreadInput>(
        CREATE_THREAD_MUTATION,
        { userId },
        'Create Thread',
        this.licenseKey
      ) as GraphQLResult<CreateThreadOutput>;
      const threadId = threadMutationResult.data.createThread.threadId;
      this.chatContext.setIsNewThreadCreated(true);
      return threadId;
    } catch (error: any) {
      this.handleError(error, 'createThread');
      return '';
    }
  }

  async sendMessage(content: string, threadIdFromParser: string | null = null) {
    if (!this.validateBasicAuthentication()) return;

    let currentThreadId = this.chatContext.threadId || threadIdFromParser;

    const specificTable = extractTableName(window.sessionStorage.getItem('tabTitle') ?? '');

    this.setState((prevState: any) => ({
      ...prevState,
      specificTableQuestion: specificTable,
    }));

    if (!currentThreadId) {
      trackMixpanel('No thread ID', { error: 'Thread ID not set' });
      console.error("Thread ID is not set. Cannot send message.");
      this.chatContext.setBlockingStatus(false);
      return;
    }
    mixpanel.identify(window.sessionStorage.getItem('licenseKey') || undefined) // AI-GEN - Cursor
    mixpanel.track('Message Sent', { threadId: currentThreadId });

    try {
      await performMutation<SendMessageInput>(SEND_MESSAGE_MUTATION, {
        threadId: currentThreadId,
        content,
        authToken: this.licenseKey || '',
        userId: this.MGRUserId
      }, 'Send Message', this.licenseKey);
    } catch (error: any) {
      this.handleError(error, 'sendMessage');
    }
  }

  async submitToolOutputs(
    threadId: string,
    runId: string,
    toolOutputs: ToolOutput[],
  ) {
    if (!this.validateBasicAuthentication()) return;

    if (!threadId || !runId) {
      console.error("Thread ID or Run ID is not set. Cannot submit tool outputs.");
      return;
    }

    const authToken: string = this.licenseKey;

    try {
      const input: SendMessageInput = {
        threadId,
        authToken,
        userId: this.MGRUserId,
        toolOutputs, // Array of ToolOutput objects
        runId // Include the runId to track which session the toolOutputs belongs to
      };

      await performMutation<SendMessageInput>(SEND_MESSAGE_MUTATION, input, 'Send Message', this.licenseKey);
    } catch (error: any) {
      this.handleError(error, 'submitToolOutputs');
    }
  }

  // Helper function to fetch data and filter non-empty content
  async fetchDataAndFilter(fetchFunction: () => Promise<ApiResponse>, dataTable: DataTable) {
    const data = await fetchFunction();
    return data.content.length > 0 ? { [dataTable]: data } : {};
  }

  async handleRequiredActions(event: PublishResultOutput) {
    if (!this.validateBasicAuthentication()) return;

    const {
      requiredAction,
      threadId,
      runId,
    } = event;

    // Add a guard clause to check if requiredAction is defined
    if (!requiredAction) {
      this.amendLatestMessage("Failed to send MGR request, please try again...");
      this.partialMessage = '';
      this.chatContext.setBlockingStatus(false);
      return;  // Exit if there is no requiredAction defined
    }

    // Guard Clause for action type and tool calls existence
    if (requiredAction.type !== 'submit_tool_outputs') {
      this.amendLatestMessage("Failed to send MGR request, please try again...");
      this.partialMessage = '';
      this.chatContext.setBlockingStatus(false);
      return; // Exit early if no tool calls
    }

    let specificTableQuestion: string;

    this.setState((prevState: any) => {
      specificTableQuestion = prevState.specificTableQuestion;

      return {
        ...prevState,
      }
    });

    console.log("Fetching Tool Outputs....");

    // Map of DataTable values to corresponding fetch methods
    const dataTableFetchMap: { [key in DataTable]: () => Promise<ApiResponse> } = {
      [DataTable.FafCustomListTable]: this.MGRClient.fetchCustomListTableData.bind(this.MGRClient),
      [DataTable.FafChainsTable]: this.MGRClient.fetchChainsTableData.bind(this.MGRClient),
      [DataTable.FafConditionsTable]: this.MGRClient.fetchConditionsTableData.bind(this.MGRClient),
      [DataTable.FafEciConnectionsTable]: this.MGRClient.fetchEciConnectionsTableData.bind(this.MGRClient),
      [DataTable.FafFiltersTable]: this.MGRClient.fetchFiltersTableData.bind(this.MGRClient),
      [DataTable.fafBulkTable]: this.MGRClient.fetchBulkTableData.bind(this.MGRClient),
      [DataTable.fafCustomBlocksTable]: this.MGRClient.fetchCustomBlocksTableData.bind(this.MGRClient),
      [DataTable.fafDeltaTable]: this.MGRClient.fetchDeltaTableData.bind(this.MGRClient),
      [DataTable.fafDupsTable]: this.MGRClient.fetchDupsTableData.bind(this.MGRClient),
      [DataTable.fafEmsTable]: this.MGRClient.fetchEmsTableData.bind(this.MGRClient),
      [DataTable.fafEvalTable]: this.MGRClient.fetchEvalTableData.bind(this.MGRClient),
      [DataTable.fafFloodTable]: this.MGRClient.fetchFloodTableData.bind(this.MGRClient),
      [DataTable.fafPollingGroupsTable]: this.MGRClient.fetchPollingGroupsTableData.bind(this.MGRClient),
      [DataTable.fafSpreadTable]: this.MGRClient.fetchSpreadTableData.bind(this.MGRClient),
      [DataTable.fafStringTable]: this.MGRClient.fetchStringTableData.bind(this.MGRClient),
      [DataTable.fafVolumeTable]: this.MGRClient.fetchVolumeTableData.bind(this.MGRClient)
    };

    let toolOutputs: ToolOutput[];

    try {
      const fetchOutputPromises = requiredAction.submitToolOutputs.toolCalls.map(async (toolCall) => {
        const func = toolCall.function.name
        console.log('Func >> ', func);

        if (func === 'get_faf_config') {
          const results: { [key: string]: ApiResponse } = {};

          for (const [key, fetchFunction] of Object.entries(dataTableFetchMap)) {
            const result = await this.fetchDataAndFilter(fetchFunction, key as DataTable);
            Object.assign(results, result);
          }

          const filteredToolOutputs = filterJson(
            results,
            specificTableQuestion
          );

          const output = isEmpty(filteredToolOutputs) ? 'Empty Configuration' : JSON.stringify(filteredToolOutputs);
          await new Promise(resolve => setTimeout(resolve, 300));  // Adding a delay of 300 ms between each API call

          return { toolCallId: toolCall.id, output };
        } else if (func === 'get_current_screen') {
          const output = window.sessionStorage.getItem('tabTitle') || ''
          return { toolCallId: toolCall.id, output };
        } else {
          return { toolCallId: toolCall.id, output: '' };
        }
      });

      toolOutputs = await Promise.all(fetchOutputPromises);

      console.log(`Finish Fetching Tool Outputs....`);
    } catch (error: any) {
      const validError = this.handleError(error, 'handleRequiredActions');

      if (!validError) return;

      toolOutputs = requiredAction.submitToolOutputs.toolCalls.map((toolCall) => {
        return { toolCallId: toolCall.id, output: ERROR_MESSAGE.INVALID_MGR_CONFIGURATION.USER_MESSAGE };
      });
    }

    await this.submitToolOutputs(
      threadId,
      runId,
      toolOutputs,
    );
  }
  // AI-GEN END

  async subscribeToMessages(threadIdFromParser: string | null = null) {
    let currentThreadId = this.chatContext.threadId || threadIdFromParser; // AI-GEN - Cursor
    const subscription = await this.client.graphql<PublishResultOutput>(
      graphqlOperation(PUBLISH_RESULT_SUBSCRIPTION, { // AI-GEN - Cursor
        threadId: currentThreadId // AI-GEN - Cursor
      }, this.licenseKey) // AI-GEN - Cursor
    ) as GraphqlSubscriptionResult<MessageResponseSubscription>;

    const messageDeltaLock = createLock();

    this.messageSubscription = subscription.subscribe({
      next: async ({ data }) => {
        const {
          eventType
        } = data.onPublishResult;

        // AI-GEN START - Cursor
        if (eventType === EventType.messageCreated) {
          this.partialMessage = '';
        } else if (eventType === EventType.messageDelta) {
          await messageDeltaLock.acquire();
          try {
            const newMessage = data.onPublishResult.textDelta;
            if (newMessage.length <= this.partialMessage.length) {
              // wrong order, discard the message
              return;
            }

            const oldMessageLength = this.partialMessage.length;
            for (let i = oldMessageLength; i < newMessage.length; i++) {
              this.amendLatestMessage(newMessage.substring(0, i + 1));
              await new Promise(resolve => setTimeout(resolve, 10));
            }
            this.partialMessage = newMessage;
          } finally {
            messageDeltaLock.release();
          }
        } else if (eventType === EventType.runDone) {
          this.chatContext.setBlockingStatus(false);
        } else if (eventType === EventType.requiresAction) {
          // AI-GEN START - ChatGPT
          this.renderToolCallingWidget();
          this.handleRequiredActions(data.onPublishResult);
          this.chatContext.setBlockingStatus(true);
          // AI-GEN END
        } else if (eventType === EventType.fileSearchStart) {
          // AI-GEN START - ChatGPT
          this.renderFileSearchingWidget();
          // AI-GEN END
        } else if (eventType === EventType.fileSearchEnd) {
          // AI-GEN START - ChatGPT
          this.removeFileSearchingWidget();
          // AI-GEN END
        } else if (eventType === EventType.newSessionCreated) {
          this.chatContext.setNewCreatedSessionName(data.onPublishResult.textDelta);
          this.chatContext.setThreadId(currentThreadId);
        }
        // AI-GEN END
      },
      error: error => {
        mixpanel.identify(window.sessionStorage.getItem('licenseKey') || undefined) // AI-GEN - Cursor
        mixpanel.track('Error subscribing to messages', { error: error }); // AI-GEN - Cursor
        console.error(error)
        this.unsubscribeFromMessages(); // AI-GEN Cursor
      }
    });
  }

  unsubscribeFromMessages() {
    if (this.messageSubscription) {
      this.messageSubscription.unsubscribe();
      this.messageSubscription = null; // Reset the subscription property
      this.chatContext.setBlockingStatus(false); // AI-GEN - Cursor
    }
  }

  appendNewMessage(text: string): void {
    const message = createCustomMessage(text, 'custom', { // AI-GEN - Cursor
      widget: 'thinking', // AI-GEN - Cursor
    }); // AI-GEN - Cursor
    message.payload = message.id; // AI-GEN - Cursor
    this.setState((prevState: any) => {
      return {
        ...prevState,
        messages: [...prevState.messages, message],
      }
    });
  }

  // AI-GEN START - Cursor
  renderToolCallingWidget(): void {
    this.setState((prevState: any) => {
      const latestMessage = prevState.messages[prevState.messages.length - 1];
      latestMessage.message = 'thinking';
      latestMessage.widget = 'functionCalling';

      return {
        ...prevState,
        messages: prevState.messages,
      };
    });
  }
  // AI-GEN END

  // AI-GEN START - ChatGPT
  renderFileSearchingWidget(): void {
    this.setState((prevState: any) => {
      const latestMessage = prevState.messages[prevState.messages.length - 1];
      latestMessage.widget = 'fileSearching';

      return {
        ...prevState,
        messages: prevState.messages,
      };
    });
  }

  removeFileSearchingWidget(): void {
    this.setState((prevState: any) => {
      const latestMessage = prevState.messages[prevState.messages.length - 1];
      latestMessage.widget = undefined;

      return {
        ...prevState,
        messages: prevState.messages,
      };
    });
  }
  // AI-GEN END

  // AI-GEN START - Cursor
  amendLatestMessage(text: string): void {
    this.setState((prevState: any) => {
      const latestMessage = prevState.messages[prevState.messages.length - 1];
      latestMessage.message = text;
      latestMessage.widget = undefined; // AI-GEN - Cursor 

      return {
        ...prevState,
        messages: prevState.messages,
      };
    });
  }
  // AI-GEN End
}
// AI-GEN End
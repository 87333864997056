// AI-GEN START - ChatGPT
import _ from 'lodash';
import React, { useEffect, useState } from "react"
import { FaPlus, FaTrash, FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import ReactLoading from "react-loading";
import { useChat } from "../context/chatContext";
import { GraphQLResult } from "@aws-amplify/api";
import { ListSessionsInput, LIST_SESSIONS_QUERY, ListSessionsOutput, DeleteSessionInput, DELETE_SESSION_MUTATION, RenameSessionInput, RenameSessionOutput, RENAME_SESSION_MUTATION, SessionDetails } from "../chatbot/graphqlOperations";
import { performQuery } from "../utils/performQuery";
import { performMutation } from "../utils/performMutation";
import { cleanUpMessage } from '../utils/messageHelper';

const SESSIONS_PER_PAGE = 10;

type SidebarProps = {
  isLoading?: boolean
};

export const Sidebar = ({
  isLoading = false
}: SidebarProps) => {
  const [nextToken, setNextToken] = useState<string | undefined>();
  const [renamingSessionId, setRenamingSessionId] = useState<string | undefined>();
  const [renamingSessionName, setRenamingSessionName] = useState<string | undefined>();
  const [isSidebarLoading, setIsSidebarLoading] = useState<boolean>(false);
  const [sessionList, setSessionList] = useState<Array<SessionDetails>>([]);
  const [isEndOfData, setIsEndOfData] = useState<boolean>();
  const [modifyingSessionId, setModifyingSessionId] = useState<string | undefined>();

  const licenseKey = window.sessionStorage.getItem('licenseKey');
  const userId = window.sessionStorage.getItem('MGRUserId') || '';

  const tenantId = `${licenseKey}#${userId}`;

  // Deduplicate by sessionId and order by lastUpdated DESC
  const deduplicatedAndOrderedSessions = _.orderBy(
    _.uniqBy(sessionList, 'sessionId'),
    ['lastUpdated'],
    ['desc']
  );

  const {
    isBlocking,
    threadId,
    newCreatedSessionName,
    setThreadId,
    setNewCreatedSessionName,
  } = useChat();

  // AI-GEN START - ChatGPT
  const createNewSession = () => {
    console.log('Creating new session...');
    cleanUpMessage();
    setThreadId(null);
    setNewCreatedSessionName(undefined);
  }

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  useEffect(() => {
    if (!newCreatedSessionName) return;

    setSessionList((prevList) => [{
      sessionId: threadId!,
      sessionName: newCreatedSessionName,
      lastUpdated: new Date().toISOString(),
      tenantId,
    }, ...prevList]);
    setNewCreatedSessionName(undefined);
  }, [newCreatedSessionName]);

  const fetchSessions = async () => {
    if (!licenseKey) {
      return;
    }

    try {
      setIsSidebarLoading(true);

      const result = await performQuery<ListSessionsInput>(
        LIST_SESSIONS_QUERY,
        { tenantId, limit: SESSIONS_PER_PAGE, nextToken },
        'List Sessions',
        licenseKey
      ) as GraphQLResult<ListSessionsOutput>;

      const fetchedSessionList = result.data?.listSessions?.sessions;

      setSessionList((prevList) => [...prevList, ...fetchedSessionList]);
      const token = result.data.listSessions.nextToken;

      setNextToken(token);

      if (!token || fetchedSessionList.length === 0) {
        setIsEndOfData(true)
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsSidebarLoading(false);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const renameSession = async (
    sessionId: string,
    sessionName: string
  ) => {
    console.log(`Renaming Session: ${sessionId}....`);
    setRenamingSessionId(sessionId);
    setRenamingSessionName(sessionName)
  }

  const cancelRenameSession = async () => {
    setRenamingSessionId(undefined);
    setRenamingSessionName(undefined);
  }

  const submitRenameSession = async () => {
    console.log(`Submit Rename Session: ${renamingSessionId}....`);

    const licenseKey = window.sessionStorage.getItem('licenseKey');

    if (!licenseKey || !renamingSessionId || !renamingSessionName) {
      return;
    }

    try {
      setModifyingSessionId(renamingSessionId);

      await performMutation<RenameSessionInput>(
        RENAME_SESSION_MUTATION,
        {
          sessionId: renamingSessionId!,
          sessionName: renamingSessionName!
        },
        'Rename Session',
        licenseKey
      ) as GraphQLResult<RenameSessionOutput>;

      // Modify the session list
      const updatedSessionList = sessionList.map(session => {
        if (session.sessionId === renamingSessionId) {
          return { ...session, sessionName: renamingSessionName };
        }
        return session;
      });

      // Save the modified session list to the state
      setSessionList(updatedSessionList);
    } catch (e) {

    } finally {
      setIsSidebarLoading(false);
      setRenamingSessionId(undefined);
      setRenamingSessionName(undefined);
      setModifyingSessionId(undefined);
    }
  };

  const deleteSession = async (sessionId: string) => {
    console.log(`Deleting Session: ${sessionId}....`);

    const licenseKey = window.sessionStorage.getItem('licenseKey');

    if (!licenseKey) {
      return;
    }

    try {
      setModifyingSessionId(sessionId);

      await performMutation<DeleteSessionInput>(
        DELETE_SESSION_MUTATION,
        { sessionId },
        'Delete Session',
        licenseKey
      ) as GraphQLResult<ListSessionsOutput>;
    } catch (e) {

    } finally {
      setModifyingSessionId(undefined);
      setSessionList((prevItems) => prevItems.filter(item => item.sessionId !== sessionId));

      if (threadId === sessionId) {
        createNewSession();
      }
    }
  }

  const selectSession = (sessionId: string) => {
    console.log(`Selecting session: ${sessionId}....`);
    setThreadId(sessionId);
  }

  return (<>
    <span id="sidebar-toggle" onClick={toggleSidebar}>&#9776;</span>
    <div id="sidebar" className={`${isSidebarOpen ? 'open' : ''}`}>
      <button className="new-chat-btn" onClick={createNewSession} disabled={isBlocking}>
        <FaPlus /> New Chat
      </button>
      <div
        style={{
          height: '90%',
          overflowY: 'scroll',
          overflowX: 'hidden',
        }}
      >
        {deduplicatedAndOrderedSessions.map((session) => {
          const { sessionId } = session;

          const isEditingSession = sessionId === renamingSessionId;
          const isActive = sessionId === threadId;
          const isModifying = sessionId === modifyingSessionId;

          return (<button
            key={sessionId}
            className={`session-item ${isActive ? 'active' : ''} ${isLoading || isSidebarLoading ? 'loading' : ''}`}
          >
            {!isEditingSession && <span onClick={() => selectSession(sessionId)}>{session.sessionName}</span>}
            {isEditingSession && <input
              style={{
                width: '100%',
              }}
              value={renamingSessionName}
              onChange={(event) => {
                setRenamingSessionName(event.target.value);
              }}
              onSubmit={submitRenameSession}
            />}
            <div
              style={{
                display: 'flex',
              }}
            >
              {isModifying && <ReactLoading height={'20px'} width={'20px'} color='black' type='spin' />}
              {!isModifying && !isEditingSession && <span className="icon" data-testid="edit-button" onClick={() => renameSession(sessionId, session.sessionName)}>
                <FaEdit />
              </span>}
              {!isModifying && !isEditingSession && <span className="icon" data-testid="delete-button" onClick={() => deleteSession(sessionId)} >
                <FaTrash color="red" />
              </span>}
              {!isModifying && isEditingSession && <span data-testid="submit-edit-button" onClick={submitRenameSession} >
                <FaCheck color="green" />
              </span>}
              {!isModifying && isEditingSession && <span data-testid="cancel-edit-button" onClick={cancelRenameSession}>
                <FaTimes color="red" />
              </span>}
            </div>
          </button>
          )
        })}
        <div
          style={{
            justifyContent: 'center',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: 210,
            paddingTop: 20,
          }}
          className="loading"
        >
          {isSidebarLoading && <ReactLoading height={'20px'} width={'20px'} color='black' type='spin' />}
          {!isEndOfData && !isSidebarLoading && <div onClick={fetchSessions} className='load-more'>Load More...</div>}
        </div>
      </div>
    </div>
  </>);
}
// AI-GEN END

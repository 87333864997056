// AI-GEN START - ChatGPT
import { GraphQLResult } from "@aws-amplify/api";
import { graphqlOperation, GraphqlSubscriptionResult } from '@aws-amplify/api-graphql';

import { CreateThreadInput, DeleteSessionInput, DeleteSessionOutput, GetMessageHistoryInput, SendMessageInput } from "../chatbot/graphqlOperations";
import { AppSyncClient } from "../chatbot/appsyncClient";
import { trackMixpanel } from "./trackMixpanel";

export const performMutation = async <T extends DeleteSessionInput | GetMessageHistoryInput | CreateThreadInput | SendMessageInput>(
  operation: any,
  variables: T,
  operationName: string,
  licenseKey: string
): Promise<GraphQLResult<any> | GraphqlSubscriptionResult<any>> => {
  try {
    const client = AppSyncClient.generateClient();

    const result = await client.graphql<GraphQLResult<DeleteSessionOutput>>(
      graphqlOperation(operation, { input: variables }, licenseKey)
    );
    return result;
  } catch (error: any) {
    let errorMessage = '';


    if (error.errors && error.errors[0].errorType === 'Lambda:ExecutionTimeoutException') { // AI-GEN - Cursor
      errorMessage = `[${operationName}] Lambda execution timeout: ${error}`;
    } else if (error.errors && error.errors[0].message === 'Unauthorized') { // AI-GEN - Cursor
      errorMessage = `[${operationName}] Unauthorized: ${error}`; // AI-GEN - Cursor
    } else {
      errorMessage = `[${operationName}] Error perform GraphQL Mutation: ${error}`;
    }

    trackMixpanel(errorMessage, variables);
    console.error(errorMessage);

    throw error; // Or handle it accordingly
  }
};
// AI-GEN END
export const ERROR_MESSAGE = {
  NO_LICENSE_KEY: {
    USER_MESSAGE: 'Cannot send message. No license key provided',
    DEBUG_MESSAGE: 'No license key set in the session storage'
  },
  NO_MGR_SESSION_ID: {
    USER_MESSAGE: 'Failed to retrieve user configuration. Please refresh the page',
    DEBUG_MESSAGE: 'Cannot send message. No MGR Session ID provided',
  },
  NO_MGR_URL: {
    USER_MESSAGE: 'Failed to retrieve user configuration. Please refresh the page',
    DEBUG_MESSAGE: 'Cannot send message. No MGR URL provided',
  },
  INVALID_MGR_CONFIGURATION: {
    USER_MESSAGE: 'Failed to retrieve user configuration. Please refresh the page',
    DEBUG_MESSAGE: 'Failed on submit tools output due to failed to fetch MGR configuration',
  },
};
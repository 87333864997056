// AI-GEN START - ChatGPT
import { DataTable } from "./MGRClient";

type JsonData = {
  [key: string]: any;
};

type ParsedKey = {
  table: string | null;
  index1: string | null;
  index2: string | null;
};

// Define constants for string values
const TABLE_FAF_CHAINS = 'fafChains';
const TABLE_FAF_CUSTOM_BLOCKS = 'fafCustomBlocks';
const TABLE_FAF_CUSTOM_LIST = 'fafCustomList';

const FIELD_FAF_BLOCKS_TYPE = 'fafBlocksType';
const FIELD_FAF_BLOCKS_NAME = 'fafBlocksName';

const TABLE_ATTRIBUTES_MAPPING = {
  [TABLE_FAF_CHAINS]: {
    tableName: DataTable.FafChainsTable,
    indexName1: 'fafChainsIndex1',
  },
  [TABLE_FAF_CUSTOM_LIST]: {
    tableName: DataTable.FafCustomListTable,
    indexName1: 'fafCustomListIndex',
  },
  [TABLE_FAF_CUSTOM_BLOCKS]: {
    tableName: DataTable.fafCustomBlocksTable,
    indexName1: 'fafBlocksIndex1',
    indexName2: 'fafBlocksIndex2',
  }
}

const UI_TABLE_NAMES_PATTERN = [
  /^\/fafChains$/,
  /^\/fafCustomList$/,
  /^\/fafChains\/#\d+$/,
  /^\/fafCustomList\/#\d+$/,
  /^\/fafCustomBlocks\/#\d+(\.\d+)$/
];

const blockTypeToSubTable: { [key: number]: { table: string, nameField: string } } = {
  "21": { table: DataTable.fafEvalTable, nameField: 'fafEvalName' },
  "22": { table: DataTable.fafStringTable, nameField: 'fafStringName' },
  "24": { table: DataTable.fafDupsTable, nameField: 'fafDupsName' },
  "25": { table: DataTable.fafFloodTable, nameField: 'fafFloodName' },
  "26": { table: DataTable.fafEmsTable, nameField: 'fafEmsName' },
  "27": { table: DataTable.fafVolumeTable, nameField: 'fafVolumeName' },
  "41": { table: DataTable.fafBulkTable, nameField: 'fafBulkName' },
  "42": { table: DataTable.fafSpreadTable, nameField: 'fafSpreadName' },
  "43": { table: DataTable.fafDeltaTable, nameField: 'fafDeltaName' },
};

// fafCustomBlocksTable are mirroring table, we can safely ignore after we find the linkage
const duplicateTable = [
  DataTable.fafCustomBlocksTable
];

// Helper function to get the table name and indices from the input key
export const parseKey = (key: string): ParsedKey => {
  const [table, index] = key.split('/').slice(1);

  if (!table || !index) {
    return {
      table: table === '' ? null : table,
      index1: null,
      index2: null,
    }
  }

  const indices = index.match(/#(\d+)(?:\.(\d+))?/);
  return {
    table,
    index1: indices ? indices[1] : null,
    index2: indices && indices[2] ? indices[2] : null
  };
};


// Function to get sub-table content based on fafBlocksType and fafBlocksName
export const getSubTableContent = (blockType: number, blockName: string, jsonData: JsonData): JsonData => {
  const result: JsonData = {};

  const subTableMapping = blockTypeToSubTable[blockType];
  if (subTableMapping) {
    const { table, nameField } = subTableMapping;
    if (jsonData[table]) {
      const matchedEntry = jsonData[table].content.find((item: any) => item[nameField] === blockName);
      if (matchedEntry) {
        if (!result[table]) {
          result[table] = { ...jsonData[table], content: [] };
        }
        result[table].content.push(matchedEntry);
      }
    }
  }

  return result;
};

// Function to filter content based on indices for fafChainsTable or fafCustomListsTable
export const filterTableContent = (jsonData: JsonData, indexName1: string, index1: string): JsonData => {
  const filteredContent = jsonData.content.filter((item: { [x: string]: string; }) => item[indexName1] === index1);

  return {
    ...jsonData,
    content: filteredContent
  }
};


// Function to filter content based on indices for fafCustomBlocksTable
export const findCustomBlocksContent = (
  jsonData: JsonData,
  indexName1: string,
  index1: string,
  indexName2: string,
  index2: string
): JsonData => {
  const filteredContent = jsonData.content.find((item: { [x: string]: string; }) => item[indexName1] === index1 && item[indexName2] === index2);

  return filteredContent;
};

export const safeRemoveDuplicateTables = (jsonData: JsonData) => {
  duplicateTable.forEach((val) => {
    if (jsonData.hasOwnProperty(val)) {
      delete jsonData[val];
    }
  });
  return jsonData;
}

export const filterJson = (jsonData: JsonData, inputKey?: (string | null)): JsonData => {
  let result: JsonData = {};

  if (!inputKey || !UI_TABLE_NAMES_PATTERN.some(pattern => pattern.test(inputKey))) {
    return safeRemoveDuplicateTables(jsonData);
  }

  const { table, index1, index2 } = parseKey(inputKey);

  if (table === 'fafChains' && !index1 && !index2) {
    delete jsonData[DataTable.FafCustomListTable];

    return safeRemoveDuplicateTables(jsonData);
  }

  if (table === 'fafCustomList' && !index1 && !index2) {
    return {
      [DataTable.FafCustomListTable]: jsonData[DataTable.FafCustomListTable]
    };
  }

  if (
    (table === TABLE_FAF_CUSTOM_LIST || table === TABLE_FAF_CHAINS)
    && index1
  ) {
    const { tableName, indexName1 } = TABLE_ATTRIBUTES_MAPPING[table];

    const dataTable = filterTableContent(
      jsonData[tableName],
      indexName1,
      index1
    );

    result[tableName] = dataTable;
  }

  if (table === TABLE_FAF_CUSTOM_BLOCKS && index1 && index2) {
    const { tableName, indexName1, indexName2 } = TABLE_ATTRIBUTES_MAPPING[table];
    const chainsAttribute = TABLE_ATTRIBUTES_MAPPING[TABLE_FAF_CHAINS];

    // Custom Blocks is always on top of Chains
    const dataTable = filterTableContent(
      jsonData[chainsAttribute.tableName],
      chainsAttribute.indexName1,
      index1
    );
    result[chainsAttribute.tableName] = dataTable;

    const customBlocksContent = findCustomBlocksContent(
      jsonData[tableName],
      indexName1,
      index1,
      indexName2,
      index2
    );

    const blockType = customBlocksContent[FIELD_FAF_BLOCKS_TYPE];

    const subTableContent = getSubTableContent(
      blockType,
      customBlocksContent[FIELD_FAF_BLOCKS_NAME],
      jsonData
    );

    result = {
      ...subTableContent,
      [chainsAttribute.tableName]: dataTable
    };
  }

  return safeRemoveDuplicateTables(result);
};
// AI-GEN END
// AI-GEN START - ChatGPT
import { gql } from 'graphql-tag';

export type CreateThreadInput = {
  userId: string;
}

export type CreateThreadOutput = {
  // AI-GEN Start - Cursor
  createThread: {
    threadId: string;
    userId: string;
    createdAt: string;
  } // AI-GEN End
};

export const CREATE_THREAD_MUTATION = gql`
  mutation CreateThread($input: CreateThreadInput!) {
    createThread(input: $input) {
      threadId
      userId
      createdAt
    }
  }
`;

export type ListSessionsInput = {
  tenantId: string;
  limit?: number;
  nextToken?: string;
}

export type ListSessionsOutput = {
  listSessions: {
    sessions: SessionDetails[];
    nextToken?: string;
  }
}

export type SessionDetails = {
  sessionId: string;
  lastUpdated: string;
  tenantId: string;
  sessionName: string;
}

export const LIST_SESSIONS_QUERY = gql`
  query ListSessions($input: ListSessionsInput!) {
    listSessions(input: $input) {
      sessions {
        sessionId
        lastUpdated
        tenantId
        sessionName
      }
      nextToken
    }
  }
`;

// AI-GEN START - ChatGPT
export type ToolOutput = {
  toolCallId: string;
  output: string;
}

export type SendMessageOutput = {
  threadId: string;
  createdAt: string;
};
// AI-GEN END

export type SendMessageInput = {
  threadId: string;
  content?: string; // AI-GEN - ChatGPT
  authToken: string; // AI-GEN - Cursor
  userId: string;
  runId?: string; // AI-GEN - ChatGPT
  toolOutputs?: ToolOutput[]; // AI-GEN - ChatGPT
};

// AI-GEN START - Cursor
export type PublishResultOutput = {
  threadId: string;
  runId: string; // AI-GEN - Cursor
  eventType: EventType; // AI-GEN - Cursor
  textDelta: string; // AI-GEN - Cursor
  createdAt: string; // AI-GEN - Cursor
  requiredAction?: RequiredAction; // AI-GEN - Cursor
  stepDetails?: StepDetailsOutput;
};
// AI-GEN END

// AI-GEN START - Cursor
export enum EventType {
  messageCreated = 'messageCreated',
  messageDelta = 'messageDelta',
  messageDone = 'messageDone',
  runDone = 'runDone',
  requiresAction = 'requiresAction',
  fileSearchStart = 'fileSearchStart',
  fileSearchEnd = 'fileSearchEnd',
  newSessionCreated = 'newSessionCreated',
}

export type RequiredAction = {
  submitToolOutputs: SubmitToolOutputs;
  type: string;
}

type SubmitToolOutputs = {
  toolCalls: ToolCall[];
}

type ToolCall = {
  id: string;
  function: ToolFunction
  type: string;
}

type ToolFunction = {
  arguments: string;
  name: string;
}
// AI-GEN END

export const SEND_MESSAGE_MUTATION = gql`
  mutation SendMessage($input: SendMessageInput!) {
    sendMessage(input: $input) {
      threadId
      createdAt
    }
  }
`;

// AI-GEN START - Cursor
export const PUBLISH_RESULT_SUBSCRIPTION = gql`
  subscription OnPublishResult($threadId: String!) {
    onPublishResult(threadId: $threadId) {
      threadId
      runId
      eventType
      textDelta
      createdAt
      requiredAction {
        type
        submitToolOutputs {
          toolCalls {
            id
            function {
              name
              arguments
            }
            type
          }
        }
      }
      stepDetails {
        type
        toolCalls {
          id
          type
        }
      }
    }
  }
`;
// AI-GEN END

// AI-GEN Start - Cursor
export type MessageResponseSubscription = {
  onPublishResult: PublishResultOutput; // AI-GEN - ChatGPT
};
// AI-GEN End

// AI-GEN START - ChatGPT
export type StepDetailsOutput = {
  toolCalls: ToolCall[];
  type: string;
}
// AI-GEN END

// AI-GEN START - ChatGPT
export const DELETE_SESSION_MUTATION = gql`
  mutation DeleteSession($input: DeleteSessionInput!) {
    deleteSession(input: $input) {
      sessionId
    }
  }
`;

export type DeleteSessionInput = {
  sessionId: string;
}

export type DeleteSessionOutput = {
  sessionId: string;
}
// AI-GEN END

// AI-GEN START - ChatGPT
export const RENAME_SESSION_MUTATION = gql`
mutation RenameSession($input: RenameSessionInput!) {
  renameSession(input: $input) {
    sessionId
    sessionName
  }
}
`;

export type RenameSessionInput = {
  sessionId: string;
  sessionName: string;
}

export type RenameSessionOutput = {
  sessionId: string;
  sessionName: string;
}
// AI-GEN END

// AI-GEN START - ChatGPT
export const GET_MESSAGE_HISTORY_MUTATION = gql`
mutation GetMessageHistory($input: GetMessageHistoryInput!) {
  getMessageHistory(input: $input) {
    lastUpdated
    sessionId
    tenantId
    messages {
      content
      id
      timestamp
      role
    }
  }
}
`;

export type Message = {
  content: string;
  id: string;
  timestamp: string;
  role: string;
}

export type GetMessageHistoryInput = {
  sessionId: string;
  tenantId: string;
}

export type GetMessageHistoryOutput = {
  getMessageHistory: {
    lastUpdated: string;
    sessionId: string;
    tenantId: string;
    messages: Array<Message>;
  }
}
// AI-GEN END

// AI-GEN END
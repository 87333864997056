// AI-GEN START - ChatGPT
import mixpanel from 'mixpanel-browser';

export const trackMixpanel = (event: string, properties: any = {}) => {
    const licenseKey = window.sessionStorage.getItem('licenseKey') || undefined;

    if (!licenseKey) {
        return;
    }

    mixpanel.identify(licenseKey);
    mixpanel.track(event, properties);
}
// AI-GEN END

// AI-GEN START - ChatGPT
import axios from 'axios';

// Enums to manage dataTable values
export enum DataTable {
    FafCustomListTable = 'fafCustomListTable',
    FafChainsTable = 'fafChainsTable',
    FafConditionsTable = 'FafConditionsTable',
    FafEciConnectionsTable = 'FafEciConnectionsTable',
    FafFiltersTable = 'FafFiltersTable',
    // fafBlocksTable = 'fafBlocksTable', // Ignore this because it is a duplicate of fafCustomBlocksTable
    fafBulkTable = 'fafBulkTable',
    fafCustomBlocksTable = 'fafCustomBlocksTable', // Custom Blocks table are mirror or shadow from the original table, in example when we create expression filter, it will create a record fafEvalTable and fafCustomBlocksTable
    // fafCustomIeiTable = 'fafCustomIeiTable', // IEI is default configuration not handled by user, it will be configured on the CustomBlocks for per each configuration
    fafDeltaTable = 'fafDeltaTable',
    fafDupsTable = 'fafDupsTable',
    fafEmsTable = 'fafEmsTable',
    fafEvalTable = 'fafEvalTable',
    fafFloodTable = 'fafFloodTable',
    // fafListTable = 'fafListTable', // We can ignore this because this was a single line of word/text inside the Text field on the fafCustomListTable represented as a single record
    fafPollingGroupsTable = 'fafPollingGroupsTable',
    fafSpreadTable = 'fafSpreadTable',
    fafStringTable = 'fafStringTable',
    fafVolumeTable = 'fafVolumeTable'
}

// Generic ApiResponse type
export interface ApiResponse {
    sid: string;
    return_type: string;
    updated_result: number;
    content: any[];
    rid: string;
    max_open_tabs: string;
    lgp_query_completed: string;
    data_table: DataTable;
    result: number;
}

// MGRClient class
export class MGRClient {
    private baseUrl: string;
    private sid: string;

    constructor(baseUrl: string, sid: string) {
        this.baseUrl = baseUrl;
        this.sid = sid;
    }

    // Method to fetch data from the Custom List Table
    async fetchCustomListTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.FafCustomListTable);
    }

    // Method to fetch data from the Chains Table
    async fetchChainsTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.FafChainsTable);
    }

    // Add methods for the new tables
    async fetchConditionsTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.FafConditionsTable);
    }

    async fetchEciConnectionsTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.FafEciConnectionsTable);
    }

    async fetchFiltersTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.FafFiltersTable);
    }

    async fetchBulkTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafBulkTable);
    }

    async fetchCustomBlocksTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafCustomBlocksTable);
    }

    async fetchDeltaTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafDeltaTable);
    }

    async fetchDupsTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafDupsTable);
    }

    async fetchEmsTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafEmsTable);
    }

    async fetchEvalTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafEvalTable);
    }

    async fetchFloodTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafFloodTable);
    }

    async fetchPollingGroupsTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafPollingGroupsTable);
    }

    async fetchSpreadTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafSpreadTable);
    }

    async fetchStringTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafStringTable);
    }

    async fetchVolumeTableData(): Promise<ApiResponse> {
        return this.fetchDataFromApi(DataTable.fafVolumeTable);
    }

    // Private method to handle the API request
    private async fetchDataFromApi(dataTable: DataTable): Promise<ApiResponse> {
        const queryParams = new URLSearchParams({
            sid: this.sid,
            data_table: dataTable,
            return_type: 'list',
            return_format: 'json'
        }).toString();

        const url = `${this.baseUrl}/tpm?${queryParams}`;

        try {
            const response = await axios.get<ApiResponse>(url);
            return response.data;
        } catch (error) {
            console.error('Error fetching data:', error);
            throw error;
        }
    }
}
// AI-GEN END
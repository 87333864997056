// AI-GEN START - Cursor
import React from 'react';

export const Thinking = () => {
  return (
    <div className="thinking">FAF Copilot is thinking...</div>
  );
};

export default Thinking;
// AI-GEN END
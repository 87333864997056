// AI-GEN START - Cursor
import React from 'react';

type ToolCallingProps = {
  text: string;
}

const ToolCalling = ({
  text
}: ToolCallingProps) => {
  return (
    <div className="tool-calling">
      <div className="spinner"></div>
      <span>{text}</span>
    </div>
  );
};

export default ToolCalling;
// AI-GEN END
import { GetMessageHistoryOutput } from "../chatbot/graphqlOperations";

export type ChatBotMessage = {
  message: string;
  type: 'text';
  id: number;
  timestamp: string;
  role: 'user' | 'assistant';
};

export const transformMessages = (data: GetMessageHistoryOutput): Array<ChatBotMessage> => {
  const messages = data.getMessageHistory.messages;

  if (!messages) return [];

  // Sort messages by timestamp
  messages.sort((a: { timestamp: string; }, b: { timestamp: string; }) => new Date(a.timestamp).getTime() - new Date(b.timestamp).getTime());

  return messages.map((message: { content: any; role: any; timestamp: any; }, index: number) => {
    const {
      content,
      role,
      timestamp,
    } = message;

    return {
      message: content,
      type: 'text',
      id: index + 1,
      timestamp,
      role,
    };
  });
}

export const cleanUpMessage = () => {
  const chatWidgets = [
    '.react-chatbot-kit-user-chat-message-container',
    '.react-chatbot-kit-chat-bot-message-container',
    '.thinking',
    '.tool-calling',
  ]
  document
    .querySelectorAll(chatWidgets.join(','))
    .forEach(element => element.remove());
}
// AI-GEN START - Cursor
import React from 'react';
import ConditionallyRender from 'react-conditionally-render'; // AI-GEN Cursor
import markdownit from 'markdown-it';

// AI-GEN START - ChatGPT
type TypeFile = {
  FILE_ID: string;
  DISPLAY_NAME: string;
  URL: string;
};

type MapFileType = {
  [key: string]: TypeFile;
}

const { REACT_APP_TAG } = process.env;
const FORMATTED_TAG = `${REACT_APP_TAG ? `/${REACT_APP_TAG}` : ''}`;

const MAP_FILE: MapFileType = {
  '1': {
    FILE_ID: 'file-T0tHE4wuQMdaJUIFvKzMeOXb',
    DISPLAY_NAME: 'ZMM_MGR_R04.22.06_Operator_Manual_22.6_rev_a',
    URL: `https://static.app.skyvera.com${FORMATTED_TAG}/manual/ZMM_MGR_R04.22.06_Operator_Manual_22.6_rev_a.pdf`
  },
  '2': {
    FILE_ID: 'file-e0PrN3Yq8B9mzatILbn0R7F2',
    DISPLAY_NAME: 'ZMM_FAF_R02.12.05_Operator_Manual_22.6_rev_a',
    URL: `https://static.app.skyvera.com${FORMATTED_TAG}/manual/ZMM_FAF_R02.12.05_Operator_Manual_22.6_rev_a.pdf`
  },
  '3': {
    FILE_ID: 'file-009nmd2sPmWVNIAQZz27dfrd',
    DISPLAY_NAME: 'FAF User Operating Instructions Manual',
    URL: `https://static.app.skyvera.com${FORMATTED_TAG}/manual/FAF%20User%20Operating%20Instructions%20Manual_ver4.pdf`
  },
  '4': {
    FILE_ID: 'file-LDcZzeM7hdmKhp8z8Dsjs3qX',
    DISPLAY_NAME: 'ZMM_Firewall_Advanced_Filtering_Guide_22.6_rev_a',
    URL: `https://static.app.skyvera.com${FORMATTED_TAG}/manual/ZMM_Firewall_Advanced_Filtering_Guide_22.6_rev_a.pdf`,
  },
}
// Adjusted regular expression to identify any content in between 【】
const urlPattern = /(【|\[|\(file-)(.*?)(】|\]|\))/g;

type ParsedUrl = {
  displayName: string;
  url: string;
}

export const parsedUrl = (part: string): ParsedUrl | undefined => {
  const index: string = part.split(':')[0];
  const reference = part.split('†')[1];

  if (reference !== 'source') {
    return;
  };

  if (!(index in MAP_FILE)) return;

  const selectedFile = MAP_FILE[index];

  return {
    displayName: selectedFile.DISPLAY_NAME,
    url: selectedFile.URL,
  }
}

const md = markdownit();

export const formatMessage = (rawText: string = ''): string => {
  let text = rawText.replace(urlPattern, '');
  if (/^```markdown/.test(text)) {
    // The response may start with ""```markdown", then try to remove the start/end tag
    text = text.replace(/^```markdown/, '');
    text = text.replace(/```$/, '');
  }

  return md.render(text);
}

export const deduplicateParsedUrl = (arr: ParsedUrl[]): ParsedUrl[] => {
  const map = new Map();
  const key = 'displayName';

  arr.forEach(item => {
    if (!map.has(item[key])) {
      map.set(item[key], item);
    }
  });
  return Array.from(map.values());
}

export const formatUrl = (rawText: string = ''): ParsedUrl[] => {
  // Split the text into parts: words and URL-like contents
  const parts = rawText.split(urlPattern);

  const parsedUrls: ParsedUrl[] = parts.map((part) => {
    if (part === '' || !part) {
      return null;
    }

    const parsedUrlData = parsedUrl(part);

    if (parsedUrlData === undefined) {
      return null;
    }

    return parsedUrlData;
  }).filter((item): item is ParsedUrl => item !== null);

  return deduplicateParsedUrl(parsedUrls);
}
// AI-GEN END

const CustomMessage = (props: any) => {
  const chatBoxCustomStyles = { backgroundColor: '' };

  // Get the last message
  const messageId = props.payload; // AI-GEN - Cursor
  const messageObject = props.state.messages.find((message: any) => message.id === messageId); // AI-GEN - Cursor
  const message: string = messageObject ? messageObject.message : ''; // AI-GEN - Cursor
  if (messageObject.role === 'user') {
    return (
      <div className="react-chatbot-kit-user-chat-message-container">
        <div className="react-chatbot-kit-user-chat-message">
          {message}
          <div className="react-chatbot-kit-user-chat-message-arrow"></div>
        </div>
        <div className="react-chatbot-kit-user-avatar">
          <div className="react-chatbot-kit-user-avatar-container">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" className="react-chatbot-kit-user-avatar-icon">
              <path d="M256 288c79.5 0 144-64.5 144-144S335.5 0 256 0 112 64.5 112 144s64.5 144 144 144zm128 32h-55.1c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16H128C57.3 320 0 377.3 0 448v16c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-16c0-70.7-57.3-128-128-128z"></path>
            </svg>
          </div>
        </div>
      </div>
    )
  }

  const renderMessage = message === 'thinking'; // AI-GEN - Cursor

  const parsedUrlData = formatUrl(message);

  return (
    // AI-GEN START - Cursor
    <ConditionallyRender
      condition={renderMessage}
      show={
        <div></div>
      }
      elseShow={
        <div className="react-chatbot-kit-chat-bot-message-container">
          <div className="react-chatbot-kit-chat-bot-avatar">
            <div className="react-chatbot-kit-chat-bot-avatar-container">
              <p className="react-chatbot-kit-chat-bot-avatar-letter">B</p>
            </div>
          </div>
          <div
            className="react-chatbot-kit-chat-bot-message"
            style={chatBoxCustomStyles}
          >
            <div dangerouslySetInnerHTML={{ __html: formatMessage(message) }}></div>
            {parsedUrlData.length > 0 && <div>
              <hr />
              <ol>
                {parsedUrlData.map((value) => {
                  return (<li key={value.displayName} >
                    <a href={value.url} target="_blank" rel="noopener noreferrer">{value.displayName}</a>
                  </li>);
                })}
              </ol>
            </div>}
          </div>
        </div>
      }
    />
    // AI-GEN END
  );
};

export default CustomMessage;
// AI-GEN END